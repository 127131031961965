import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { Image, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`Why don't you make trailguide as an app?`}</h3>
    <br />
    <p>
  We often get asked the question "Why don't you make Trailguide as an app?".
  <br />
  <br />
  There are several reasons why this is the case, and below I try to list some of
  the reasons why we don't, and one reason why we should.
    </p>
    <br />
    <br />
    <h4>{`Reasons why we do not make Trailguide as a native app:`}</h4>
    <ul>
      <li parentName="ul">
        <b>Having one codebase</b>: If we created Trailguide as a native app, we would
need to have three codebases. One for iOS (Swift), one for Android (Java or
Kotlin), and one for the Web (JavaScript). Having three codebases would
require a lot more developer skills and resources, something we do not
currently have. There exists a few well-known solutions that enable you to
"wrap" a web-app in a native app so that it can be published in the app store.
However, these solutions have proven not to work and scale very well over
time.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <b>Development speed</b>: Having only a single codebase instead of three makes
us a lot more efficient. And a lot more efficient than three times as fast as
you might think would be natural. If we decided to make Trailguide as a native
app, we would at least need a development team of 5-6 people working on the
project to keep up with the current speed we have now. And no, ChatGPT would
not help.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <b>Immediate access</b>: Among the most favorable features in our <Link to="/solutions/destination" mdxType="Link">
  commercial products
        </Link> developed on the Trailguide platform is having the ability for end
users to immediatly access information and functionality by scanning a QR
code. This is only possible with a well-written web app. Even though you can
scan a QR code for a native app, it would still require you to go through a
process of downloading, installing, and opening the app before you can access
the information. At the time you have installed and opened the app, you have
to start the process of searching for the information you were looking for.
With a web app, you can open the app and the information you are looking for
in one easy step, and it takes about one second.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <b>White label products</b>: Trailguide offers white-label tour guide apps for
tourist destinations and resorts. This is extremely easy to set up and create
for us using web apps (takes about a minute) as opposed to having to configure
and publish a custom native app (weeks). Using a native app for this would
also require lots of work each time there are bug fixes or improvements. Each
custom app would need to be updated separately.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <b>Flexibility</b>: By using web technology we can use the same code to make <Link to="/products/plugin" mdxType="Link">
  web integration
        </Link> solutions for our customers. There is no need to write separate code
for this. The only thing we need to do is to write a thin wrapper around the
trailguide app which configures it properly and loads the desired content. It
takes literally just 50 lines of code to accomplish this.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <b>Avoiding the sour Apple</b>: By creating our app as a web app we do not
have to be dependent on the app store and the rules and regulations that Apple
sets for apps. We don't need to pay Apple 30% of anything we would earn from
the app, giving us a lot more flexibility in our pricing model for our
commercial products.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <b>Quick and frequent deployment</b>: By having a well written web app and a
streamlined deployment process, we can fix bugs, make enhancements, create new
features, and deploy new version many times per day. This is something we do a
lot, and always on a Friday afternoon before I close my computer for the
weekend. Every time you open the app, the latest and greatest version will
load quietly in the background, always keeping it updated. If Trailguide were
a native app, we would need to go through a long approval process prior to
each new release and it could potentially take weeks before problems were
fixed and deployed to users and customers.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <b>It works as an app and can be installed</b>: <Link to="/howto/install" mdxType="Link">
  Installing a web app
        </Link> is as easy as adding it to your home screen on iOS or selecting
"Install app" on Android. It will then work exactly as a native app, even
offline (my 71 year old mother did this in 9 seconds).
        <br />
        <br />
      </li>
      <li parentName="ul">
        <b>(almost) All features are available</b>: Most of the features we would like
to access on a device is available through standardized APIs (Application
Programmers Interface) in the web browser. There might still be a fewe
problems if you want to perform some tasks in the background while the phone
is locked. As time goes by, more and more of the features in a device is made
available, and the difference between web apps and native apps is getting
smaller. Apple and their devices are a bit of a problem. They are
intentionally slow to adapt standards, because they know it will cannibalize
their app store (they will of course not admit to this practice publically).
In any case, they will have to support standards eventually or they end up as
Internet Explorer 6 for those who remember how that was.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <b>Works everywhere</b>: Since Trailguide is made using standard web
technology it will in theory run on any available modern web browser,
including on a Tesla if you want a great alternate navigation system for your
car.
        <br />
        <br />
      </li>
    </ul>
    <h4>{`Reasons why we should make Trailguide as a native app:`}</h4>
    <ul>
      <li parentName="ul">
        <b>App store</b>: The single reason I see why we should make Trailguide a
native app is for it to be available in the app store, it being the expected
place people go to find and install apps. And that is unfortunately a big
reason (for now). However, I do believe market forces, standards, and
regulations will result in alternate ways of installing apps. This will
include automated, intuitive ways of installing web apps such as Trailguide.
For now, we continue our focus on developing Trailguide and our platform using
web technology.
      </li>
    </ul>
    <p>
  <br />
  <br />
  Regards,
  <br />
  Bjørn Jarle Kvande
  <br />
  Developer in Chief
  <br />
  Trailguide
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      